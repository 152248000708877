<template>
  <div @click="openBigPopupFunction()">
    <vs-icon class="clickableIcon" icon="info_outline" size="small" color="dark"></vs-icon>
  </div>
</template>

<script>
export default {
  props: ["mwb_id", "person_id"],
  methods: {
    openBigPopupFunction() {
      // console.log(this.mwb_id)
      this.openBigPopup(this.mwb_id, this.person_id);
    },
  },
  mounted() {
    // console.log(this.mwb_id);
  },
};
</script>

<style>
.clickableIcon {
  cursor: pointer;
}
</style>
