<template>
  <div @click="fetchRecording(call_log)">
    <vs-icon icon="play_arrow" size="small" color="dark"></vs-icon>
  </div>
</template>

<script>
import EventBus from "../eventbus.js";
import axios from "axios";
import constants from "../../../constants.json";

export default {
  props: {
    call_log: {
      type: Object,
      required: true,
    },
  },
  methods: {
    fetchRecording(call_log) {
      let obj = {
        call_log_id: call_log.id,
      };
      //   console.log(obj.call_log_id);
      let url = `${constants.MILES_CRM_DEV}fetchPreSignedUrl`;
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.data !== null) {
            this.playaudio(response.data.data);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    playaudio(audiofile) {
      console.log("my test", audiofile);
      this.audio = new Audio(audiofile);
      this.audio.onerror = function (error) {
        console.log(error);
        EventBus.$emit("error-finding-calllog");
      };
      EventBus.$emit("open-recording-popup", audiofile, this.call_log);
      console.log("open-recording-popup",audiofile, this.call_log);
      console.log("Event emitting");
    },
  },
};
</script>

<style></style>
