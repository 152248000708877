<template>
  <div>
    <vs-table :data="table_data">
      <template slot="thead">
        <vs-th> Candidate Name </vs-th>
        <vs-th> Phone Number </vs-th>
        <vs-th> Directory </vs-th>
        <vs-th> Call Duration(secs) </vs-th>
        <vs-th> Date & Time </vs-th>
        <vs-th> Actions </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].person_name">
            {{ data[indextr].person_name }}
          </vs-td>

          <vs-td :data="data[indextr].phone_number">
            {{ hidePhoneNumber(data[indextr].phone_number) }}
          </vs-td>

          <vs-td :data="data[indextr].directory">
            {{ data[indextr].directory }}
          </vs-td>

          <vs-td :data="data[indextr].call_duration">
            <!-- {{ Math.round(data[indextr].call_duration / 60) }}mins -->
            {{ data[indextr].call_duration }}
          </vs-td>
          <vs-td :data="data[indextr].time">
            {{ convertToDate(data[indextr].time) }}
          </vs-td>
          <vs-td :data="data[indextr].mwb_id">
          <div style="display: block ruby">
           <!-- <vs-button
              color="dark"
              size="large"
              type='flat'
              icon="info_outline"
              @click="openPopupEmit(data[indextr])"
            ></vs-button>-->
            <BigPopupInfoButtonVue
              :mwb_id="data[indextr].mwb_id"
            ></BigPopupInfoButtonVue>
            <play-button
              v-if="data[indextr].call_recording_found != 0"
              :call_log="data[indextr]"
            ></play-button>
          </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment";
import playButton from "./callLogsPlayButton.vue";
import BigPopupInfoButtonVue from "./BigPopupInfoButton.vue";
export default {
  props: ["table_data"],
  components: {
    playButton,
    BigPopupInfoButtonVue
  },
  methods: {
    hidePhoneNumber(value){
      if(value != undefined && value != '' && value != null){
        var number = value
        var mobile = ''
        if(number.slice(0,1)=='+'){
          mobile = number.substring(3)
          var phone = mobile.replace(mobile.substring(0,6), 'XXXXXX');
          return phone;
        }else{
          return number.replace(number.substring(0,6), 'XXXXXX');
        }
      }
    },
    openPopupEmit(payload) {
      this.openBigPopup(payload.mwb_id);
    },
    convertToDate(timestamp) {
      return moment(timestamp).format("DD-MMM-YYYY hh:mm:ss");
    },
  },
};
</script>

<style>
</style>