<template>
  <div>
    <vx-card actionable class="cardx" title="Call Trends" style="position: sticky;z-index: 1000;top: 80px;">
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
        >
          <v-select
            v-model="year"
            :options="years"
            placeholder="Year"
            class="w-full"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            v-model="month"
            :options="months"
            placeholder="Month"
            class="w-full"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <v-select
            v-model="team"
            :options="teams"
            placeholder="Team"
            class="w-full"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            v-model="city"
            :options="cities"
            placeholder="City"
            class="w-full"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
        >
          <vs-button
            color="dark"
            type="gradient"
            icon="search"
            @click="getData"
          ></vs-button>
        </vs-col>
      </vs-row>
    </vx-card>
    <vx-card
      actionable
      class="cardx"
      title="Weekly Trend"
      style="margin-top: 4px"
      v-if="weekly_trend.series[0].data.length > 0"
    >
      <vue-apex-charts
        ref="apexChart"
        :type="weekly_trend.chart.type"
        height="300"
        width="100%"
        :options="weekly_trend.plotOptions"
        :series="weekly_trend.series"
      ></vue-apex-charts>
    </vx-card>
    <vx-card
      actionable
      class="cardx"
      title="Spoc Trend"
      style="margin-top: 4px"
      v-if="spoc_trend.series[0].data.length > 0"
    >
      <vue-apex-charts
        ref="apexChart"
        :type="spoc_trend.chart.type"
        height="300"
        width="100%"
        :options="spoc_trend.plotOptions"
        :series="spoc_trend.series"
        @click="handleChartClick"
      ></vue-apex-charts>
    </vx-card>
    <vx-card
      actionable
      class="cardx"
      :title="`Spoc Info - ${title}`"
      style="margin-top: 4px"
      v-if="table_data.length != 0"
    >
      <call-trends-table :table_data="table_data"></call-trends-table>
      <vs-pagination :total="last_page" v-model="page"></vs-pagination>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import moment from "moment";
import callTrendsTable from "../components/pagesComponents/CallTrendsTable.vue";
import VueApexCharts from "vue-apexcharts";
import constants from "../../constants.json";
import axios from "axios";
export default {
  components: {
    "v-select": vSelect,
    callTrendsTable,
    VueApexCharts,
  },
  data() {
    return {
      table_data: [],
      months: [],
      teams: [],
      cities: [],
      years: ["2021", "2022"],
      month: "",
      team: "",
      city: "",
      year: "",
      last_page: null,
      page: 1,
      title: "",
      call_type: "",
      weekly_trend: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
          legend: {
            customLegendItems: ["Short Calls", "Long Call"],
          },
          colors:['#d4526e', '#13d8aa']
        },
        series: [
          {
            name: "Short Calls",
            data: [],
          },
          {
            name: "Long Calls",
            data: [],
          },
        ],
      },
      spoc_trend: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
          legend: {
            customLegendItems: ["Short Calls", "Long Call"],
          },
          colors:['#d4526e', '#13d8aa'],
        },
        series: [
          {
            name: "Short Calls",
            data: [],
          },
          {
            name: "Long Calls",
            data: [],
          },
        ],
      },
      weekly_data: [],
      spoc_data: [],
      selected_spoc: {},
    };
  },
  watch: {
    year() {
      this.months = moment.months();
    },
    month(val) {
      console.log("selected month", val);
      if (val != "") {
        this.getSpocData();
        this.getWeeklyData();
      }
    },
    page(val) {
      this.getTableData(this.selected_spoc, val);
    },
  },
  methods: {
    getWeeklyData() {
      let file_name = this.year + "-" + this.month + "/weekly_trend";
      this.weekly_data = [];
      axios
        .get(`${constants.MILES_CM_BACK}getCallTrends?file_name=${file_name}`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("weekly trend data", response);

          if (response.data.length != 0) {
            this.weekly_data = response.data;
          } else {
            this.$vs.notify({
              title: "No data available",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocData() {
      let file_name = this.year + "-" + this.month + "/spoc_trend";
      this.spoc_data = [];

      axios
        .get(`${constants.MILES_CM_BACK}getCallTrends?file_name=${file_name}`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("spoc trend data", response);
          if (response.data.length != 0) {
            this.spoc_data = response.data;
            this.getTeamCity();
          } else {
            this.$vs.notify({
              title: "No data available",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getTeamCity() {
      let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
      this.cities = [];
        let citys = [
          ...new Set(this.spoc_data.map((item) => item.city)),
        ].sort();
        citys.forEach(element => {
        if(city_options.includes(element)){
          this.cities.push(element);
        }
        })
      // this.cities = [...new Set(this.spoc_data.map((item) => item.city))];
      this.teams = [...new Set(this.spoc_data.map((item) => item.team))];
    },
    getData() {
      if (this.team != "" && this.city != "") {
        this.spoc_trend.series = [
          {
            name: "Short Calls",
            data: [],
          },
          {
            name: "Long Calls",
            data: [],
          },
        ];
        this.weekly_trend.series = [
          {
            name: "Short Calls",
            data: [],
          },
          {
            name: "Long Calls",
            data: [],
          },
        ];
        this.table_data = [];
        this.processSpocData();
        this.processWeeklyData();
      } else {
        this.$vs.notify({
          title: "Please select team & city",
          color: "danger",
        });
      }
    },
    processSpocData() {
      this.spoc_trend.series[0].data = [];
      this.spoc_trend.series[1].data = [];
      let short_calls = [];
      let long_calls = [];
      for (let i = 0; i < this.spoc_data.length; i++) {
        if (
          this.spoc_data[i].city == this.city &&
          this.spoc_data[i].team == this.team
        ) {
          let short_call_obj = {
            x: this.spoc_data[i].spoc_name,
            y: this.spoc_data[i].short_call,
          };
          let long_call_obj = {
            x: this.spoc_data[i].spoc_name,
            y: this.spoc_data[i].long_call,
          };
          short_calls.push(short_call_obj);
          long_calls.push(long_call_obj);
        }
      }
      if(short_calls.length != 0 || long_calls.length != 0){
         this.spoc_trend.series[0].data = short_calls;
        this.spoc_trend.series[1].data = long_calls;
      }else {
         this.$vs.notify({
          title: "Spoc Trend Data not available",
          color: "danger",
        });
      }
     
      console.log("spoc trends", this.spoc_trend.series);
    },
    processWeeklyData() {
      this.weekly_trend.series[0].data = [];
      this.weekly_trend.series[1].data = [];
      let weekly_short_calls = [];
      let weekly_long_calls = [];

      for (let i = 0; i < this.weekly_data.length; i++) {
        if (
          this.weekly_data[i].city == this.city &&
          this.weekly_data[i].team == this.team
        ) {
          let sun_short_call = {
            x: "Sunday",
            y: this.weekly_data[i].Sunday.short_call,
          };
          let mon_short_call = {
            x: "Monday",
            y: this.weekly_data[i].Monday.short_call,
          };
          let tue_short_call = {
            x: "Tuesday",
            y: this.weekly_data[i].Tuesday.short_call,
          };
          let wed_short_call = {
            x: "Wednesday",
            y: this.weekly_data[i].Wednesday.short_call,
          };
          let thu_short_call = {
            x: "Thursday",
            y: this.weekly_data[i].Thursday.short_call,
          };
          let fri_short_call = {
            x: "Friday",
            y: this.weekly_data[i].Friday.short_call,
          };
          let sat_short_call = {
            x: "Saturday",
            y: this.weekly_data[i].Saturday.short_call,
          };
          let sun_long_call = {
            x: "Sunday",
            y: this.weekly_data[i].Sunday.long_call,
          };
          let mon_long_call = {
            x: "Monday",
            y: this.weekly_data[i].Monday.long_call,
          };
          let tue_long_call = {
            x: "Tuesday",
            y: this.weekly_data[i].Tuesday.long_call,
          };
          let wed_long_call = {
            x: "Wednesday",
            y: this.weekly_data[i].Wednesday.long_call,
          };
          let thu_long_call = {
            x: "Thursday",
            y: this.weekly_data[i].Thursday.long_call,
          };
          let fri_long_call = {
            x: "Friday",
            y: this.weekly_data[i].Friday.long_call,
          };
          let sat_long_call = {
            x: "Saturday",
            y: this.weekly_data[i].Saturday.long_call,
          };
          weekly_short_calls.push(
            sun_short_call,
            mon_short_call,
            tue_short_call,
            wed_short_call,
            thu_short_call,
            fri_short_call,
            sat_short_call
          );
          weekly_long_calls.push(
            sun_long_call,
            mon_long_call,
            tue_long_call,
            wed_long_call,
            thu_long_call,
            fri_long_call,
            sat_long_call
          );
        }
      }

      if(weekly_short_calls.length != 0 || weekly_long_calls.length != 0){
         this.weekly_trend.series[0].data = weekly_short_calls;
         this.weekly_trend.series[1].data = weekly_long_calls;
      }else {
         this.$vs.notify({
          title: "Weekly Trend Data not available",
          color: "danger",
        });
      }
     
      console.log("weekly trends", this.weekly_trend.series);
    },
    handleChartClick(event, chartContext, config) {
      console.log("selected chart data", config);
      let data_point_index = config.dataPointIndex;
      let series_index = config.seriesIndex;
      if (data_point_index >= 0 && series_index >= 0) {
        this.selected_spoc =
          this.spoc_trend.series[series_index].data[data_point_index];
        let spoc_name = this.selected_spoc.x;
        let total_calls = this.selected_spoc.y;
        let call_title = "";
        this.call_type = "";
        if (series_index == 0) {
          this.call_type = "short";
          call_title = "Short Calls";
        } else if (series_index == 1) {
          this.call_type = "long";
          call_title = "Long Calls";
        }
        this.title = spoc_name + "-" + call_title + "-" + total_calls;
        this.getTableData(this.selected_spoc, 1);
      }
    },
    getTableData(selected_spoc, page) {
      this.$vs.loading();
      let spoc_id = null;
      let spoc = this.spoc_data.find((o) => o.spoc_name === selected_spoc.x);

      let timestamp = moment().year(this.year).month(this.month).startOf("month").unix();
      this.table_data = [];
      if (spoc) {
        spoc_id = spoc.user_id;
        console.log("name,id", selected_spoc.x, spoc_id);
        console.log("timestamp", timestamp);
        axios
          .get(
            `${constants.MILES_CM_BACK}getSpocCallLogsForTrends?spoc_id=${spoc_id}&call_trend_date=${timestamp}&call_type=${this.call_type}&page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            }
          )
          .then((response) => {
            console.log("getSpocCallLogsForTrends", response);
            if(response.data.spoc_call_logs.data.length != 0){
              this.table_data = response.data.spoc_call_logs.data;
              this.last_page = response.data.spoc_call_logs.last_page;
            }else {
              this.$vs.notify({
                title: "Spoc Info not available",
                color: "danger",
              });
            }
            
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.handleError(error);
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>

<style>
.small {
  max-width: 400px;
  margin: 5px auto;
}
</style>